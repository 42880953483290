import React, { memo, useEffect, useState, useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { GraphWrapper } from './styles'
import {
  plotGraph,
  stringToDateUTC,
  useEffectDebugger,
  fetchLogEntries,
  getSummerTimeDifference,
  getData,
  getUpdate,
  logg,
  setTargets
} from './GraphUtils'
import AlexWebSocket from '../websocket'
import GraphHeadline from './GraphHeadline'
import GraphStats from './GraphStats'
import GraphButtonsHours from './GraphButtonsHours'
import HeaderComponent from '../HeaderComponent/HeaderComponent'

const ENDPOINT = 'wss://stream.binance.com:9443/ws/xrpusdtT@aggTrade'

const SHOW_ALL_DATA = 'logentries'
const SHOW_LAST_WEEK = 'logentries/temp_week'
const SHOW_LAST_DAY = 'logentries/temp_day'
const SHOW_LAST_MONTH = 'logentries/temp_month'
const SHOW_LAST_YEAR = 'logentries/temp_year'
const SHOW_HOURS = 'logentries/entries_hours'

function Graph({ title, type, URL, headline, graphs }) {
  const [logEntries, setlogEntries] = useState([[]])
  const [entriesStats, setEntriesStats] = useState([{ averageValue: 0, previousAverageValue: 0 }])
  const [graphData, setgraphData] = useState('')
  const [showDataFromTimePeriod, setShowDataFromTimePeriod] = useState(SHOW_HOURS)
  const [coldestPoint, setColdestPoint] = useState([])
  const [warmestPoint, setWarmestPoint] = useState([])
  const [fetchComplete, setFetchComplete] = useState(false)
  const [lastEntryDate, setLastEntryDate] = useState(false)
  const [numberOfHours, setNumberOfHours] = useState(24)
  const [showTargetSettings, setShowTargetSettings] = useState(false)
  const [doInit, setDoInit] = useState(true)
  const [doFetchUpdate, setDoFetchUpdate] = useState(false)
  const [offset, setOffset] = useState(0)
  const [largeGraph, setLargeGraph] = useState(1)

  const msgEventHandler = (message) => {
    let newData = message.data,
      id = message.id

    // console.log('msgEventhandler got: ', message)
    // console.log('newData?.title === graphs[0].title: ', message?.title, graphs[0].title, id)
    if (id === 'crypto' && message?.title === graphs[0].title) {
      console.log('Adding: ', newData)
      setlogEntries((e) => {
        const newLogEntries = [[...e[0], newData], ...e.slice(1)]
        return newLogEntries
      })
    } else if (id === 'targets' && message?.title === graphs[0].title) {
      console.log('Targets: ', newData)
      setlogEntries((e) => {
        let newLogEntries = [...e]
        let arr = e[e.length - 1]?.length ? [...e[0]] : []
        arr[arr.length - 1].upper_target = newData.upper_target
        arr[arr.length - 1].lower_target = newData.lower_target

        return newLogEntries
      })
    } else if (id === 'ping') {
      // console.log('Got ping from server...', newData)
    } else console.log(`Received message from server: `, message)
  }

  useEffect(() => {
    try {
      if (doInit) {
        let socket = new AlexWebSocket('wss://linux.midteide.com/websocket') // using 5905
        socket.on('message', msgEventHandler)
        socket.on('close', () => setDoFetchUpdate(true))
        window.AlexSocket = socket
        setDoInit(false)
      }
    } catch (error) {
      console.log('Error in UseEffect: ', error)
    }
  }, [setDoFetchUpdate])

  // useEffect(() => {
  //   try {
  //     if (doFetchUpdate) {
  //       console.log('doFetchUpdate was set - doing update...')
  //       getUpdate(logEntries, setlogEntries, URL, graphs)

  //       // FIX IF UPDATE DOES NOT WORK:
  //       // fetchLogEntries(
  //       //   showDataFromTimePeriod,
  //       //   setFetchComplete,
  //       //   graphs,
  //       //   setlogEntries,
  //       //   URL,
  //       //   numberOfHours
  //       // )
  //     }
  //   } catch (error) {
  //     console.log('Error in UseEffect: ', error)
  //   }
  // }, [doFetchUpdate, logEntries, setlogEntries])

  useEffect(() => {
    fetchLogEntries(
      showDataFromTimePeriod,
      setFetchComplete,
      graphs,
      setlogEntries,
      URL,
      numberOfHours,
      offset,
      setEntriesStats
    )
  }, [showDataFromTimePeriod, numberOfHours, offset])

  useEffectDebugger(() => {
    if (logEntries.length === graphs.length && logEntries[0].length > 1) {
      setLastEntryDate(stringToDateUTC(logEntries[0][logEntries[0].length - 1].createdAt))
      setgraphData(
        plotGraph(
          logEntries,
          getData(graphs, logEntries, setWarmestPoint, setColdestPoint, numberOfHours),
          numberOfHours,
          offset
        )
      )
    }
  }, [fetchComplete, showDataFromTimePeriod, logEntries, plotGraph])

  useEffect(() => {
    try {
      for (let i = 0; i < Highcharts.charts.length; i++) Highcharts.charts[i].reflow()
    } catch (error) {}
  }, [largeGraph])

  return (
    <div>
      <HeaderComponent />

      <GraphWrapper offset={offset} crypto={graphs[0].title}>
        {/* <button onClick={() => getUpdate(logEntries, setlogEntries, URL, graphs)}>Update</button> */}
        <GraphHeadline
          onClick={() => setShowTargetSettings((prev) => !prev)}
          logEntries={logEntries}
          lastEntryDate={lastEntryDate}
          showTargetSettings={showTargetSettings}
          graphs={graphs}
        />
        <GraphStats
          logEntries={logEntries}
          coldestPoint={coldestPoint}
          warmestPoint={warmestPoint}
          lastEntryDate={lastEntryDate}
          avg={entriesStats.averageValue}
          prevAvg={entriesStats.previousAverageValue}
          numberOfHours={numberOfHours}
          onClick={() => getUpdate(logEntries, setlogEntries, URL, graphs)}
          offset={offset}
        />
        <GraphButtonsHours
          numberOfHours={numberOfHours}
          setNumberOfHours={setNumberOfHours}
          setOffset={setOffset}
          offset={offset}
          setLargeGraph={setLargeGraph}
          largeGraph={largeGraph}
        />
        <div style={{ maxHeight: largeGraph ? '40vh' : '20vh' }}>
          <HighchartsReact
            containerProps={{
              className: 'chartStyles',
              style: { height: largeGraph ? '40vh' : '20vh' } // offset ? '100%' : '50%' }
            }}
            highcharts={Highcharts}
            options={graphData}
          />
        </div>
        <hr />
      </GraphWrapper>
    </div>
  )
}

export default memo(Graph)
