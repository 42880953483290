import styled from '@emotion/styled'

export const DashboardWrapper = styled.div`
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(238, 236, 255, 1) 100%);
  margin: 0 auto;

  // Tablet:
  @media only screen and (min-width: 768px) {
  }

  // Desktop:
  @media only screen and (min-width: 1024px) {
  }
`

export const ClickableText = styled.div`
  color: blue;
  text-decoration: underline;

  &:hover {
    color: lightblue;
    text-decoration: none;
    cursor: pointer;
  }

  // Tablet:
  @media only screen and (min-width: 768px) {
  }

  // Desktop:
  @media only screen and (min-width: 1024px) {
  }
`
