import styled from '@emotion/styled'
import { Button, Radio } from 'antd'

export const AppWrapper = styled.div`
  margin: 0rem;
  width: 100%;
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);

  // Tablet:
  @media only screen and (min-width: 768px) {
  }

  // Desktop:
  @media only screen and (min-width: 1024px) {
    margin: 0 auto;
    width: 90%;
  }
`

export const AppContainer = styled.div`
  margin: 0rem;

  // Tablet:
  @media only screen and (min-width: 768px) {
  }

  // Desktop:
  @media only screen and (min-width: 1024px) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1px;
    grid-auto-rows: minmax(100px, auto);
  }
`
