import React, { memo, useEffect, useState, useRef } from 'react'
import { formatNumber } from './GraphUtils'

const GraphHeadline = ({ logEntries = [], lastEntryDate, onClick, graphs, showTargetSettings }) => {
  let arr = logEntries[logEntries.length - 1]?.length ? [...logEntries[0]] : []
  const [input_upper, setInput_upper] = useState((hei) => {
    return arr
  })
  const [input_lower, setInput_lower] = useState(999)
  const [input_password, setInput_password] = useState('')

  if (logEntries.length && lastEntryDate) {
    let currency = arr[arr.length - 1].type
    currency = currency.toUpperCase()
    // console.log('lastEntryDate: ', new Date(lastEntryDate).getUTCHours())

    let headline = `${arr[arr.length - 1]?.description}`
    let lower_target = parseFloat(`${arr[arr.length - 1]?.lower_target}`)
    let upper_target = parseFloat(`${arr[arr.length - 1]?.upper_target}`)
    let currentValue = parseFloat(arr[arr.length - 1]?.value)

    let prevValue = parseFloat(arr[arr.length - 2].value)
    let currentTrendAmount = currentValue - prevValue
    let currentTrendPercent = currentTrendAmount / currentValue
    document.title = `${headline.split(' ')[0]} ${currentValue}${currency}`
    let target_settings = null
    // if (showTargetSettings) {
    //   // setInput_lower(lower_target)
    //   target_settings = (
    //     <div style={{ fontSize: '1rem', textAlign: 'center' }}>
    //       <br />
    //       Lower:{' '}
    //       <input
    //         name="Lower target"
    //         type="number"
    //         value={input_lower}
    //         onChange={(e) => {
    //           let value
    //           try {
    //             value = parseFloat(e.target.value)
    //             setInput_lower(value)
    //           } catch (error) {
    //             console.log('Parse error: ', error)
    //           }
    //         }}
    //       />
    //       <br />
    //       Upper:{' '}
    //       <input
    //         name="Upper target"
    //         type="number"
    //         value={input_upper}
    //         onChange={(e) => {
    //           let value
    //           try {
    //             value = parseFloat(e.target.value)
    //             setInput_upper(value)
    //           } catch (error) {
    //             console.log('Parse error: ', error)
    //           }
    //         }}
    //       />
    //       <br />
    //       Password:{' '}
    //       <input
    //         name="Password"
    //         type="password"
    //         onChange={(e) => setInput_password(e.target.value)}
    //       />
    //       {/* <button onClick={() => console.log('Lower: ', input_lower)}> */}
    //       <br />
    //       <button
    //         style={{ margin: '0 auto' }}
    //         onClick={() => setTargets(input_lower, input_upper, graphs, input_password)}
    //       >
    //         {' '}
    //         Update targets
    //       </button>
    //     </div>
    //   )
    // }
    let trendText = (
      <span>
        <b style={{ color: currentTrendAmount > 0 ? 'green' : 'red' }}>
          {currentTrendAmount > 0 ? '+' : ''}
          {formatNumber(currentTrendAmount)} {currency}
        </b>
      </span>
    )
    let textCurrent = (
      <div>
        <span>
          Price at{' '}
          <b>
            {('0' + new Date(lastEntryDate).getUTCHours()).slice(-2)}:
            {('0' + new Date(lastEntryDate).getMinutes()).slice(-2)}:
            {('0' + new Date(lastEntryDate).getSeconds()).slice(-2)}
          </b>
          :{' '}
          <b style={{ fontSize: '2rem', textDecoration: 'underline', color: 'blue' }}>
            {formatNumber(currentValue)} {currency}
          </b>
          ({trendText})
        </span>
        <span> </span>
      </div>
    )

    return (
      <>
        <h1 style={{ marginBottom: '0', textAlign: 'center', textDecoration: 'underline' }}>
          {headline} {/* <span onClick={onClick}> */}
          {/* ({formatNumber(lower_target)}/{formatNumber(upper_target)}) */}
          {/* </span>{' '} */}
          {/* {target_settings} */}
        </h1>
        <h4>{textCurrent}</h4>
      </>
    )
  }
  return <div>Loading...</div>
}

// async function setTargets(lower_target, upper_target, graphs, password) {
//   try {
//     await fetch('https://linux.midteide.com/api/logentries/set_targets', {
//       crossDomain: true,
//       method: 'PATCH',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({
//         type: graphs[0].type,
//         title: graphs[0].title,
//         lower_target: lower_target,
//         upper_target: upper_target,
//         password: password
//       })
//     })
//       .then((res) => res.json())
//       .then((body) => {
//         console.log('PATCH got back: ', body)
//       })
//   } catch (error) {
//     console.log('Fetch failed: ', error)
//   }

//   // console.log(body);
// }

export default memo(GraphHeadline)
