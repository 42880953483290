import React, { useEffect, useState, memo } from 'react'
import Header from '../HeaderComponent/HeaderComponent'
import { DashboardWrapper } from './styles'
import RenderTable from './Table'
import { Formik, Field, Form } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { storeUser, storeUserInputs, storeAppvariables } from '../../actions'
import { Menu, Dropdown, message, Select } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Input, Tooltip } from 'antd'
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons'
const { Option } = Select

const CreatePriceAlert = ({ user, setuser }) => {
  const dispatch = useDispatch()
  const userInputs = useSelector((state) => state.userInputs)
  const DEBUG = useSelector((state) => state.appvariables.debug)
  const cryptos = useSelector((state) => state.appvariables.cryptos)

  useEffect(() => {
    const URLFull = 'https://linux.midteide.com/api/' + 'logentries/cryptos'
    console.log('Fetchingfrom: ', URLFull)
    fetch(URLFull, {
      crossDomain: true,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        crypto: 'ALL'
      }
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        console.log('App Fetched cryptos: ', data)
        dispatch(storeAppvariables('cryptos', data))
      })
      .catch((error) => {
        console.error('AllUsd Error:', error)
      })
  }, [])

  return (
    <div>
      <Formik
        initialValues={{
          pricepoint: 0,
          direction: 'Please select',
          cryptoid: userInputs.cryptoid // Object.values(cryptos)[0].id ? Object.values(cryptos)[0] : 'Fetch failed'
        }}
        onSubmit={async (values) => {
          alert('Test')
          if (userInputs.direction === 'Please select') {
            alert('Please select direction')
            // return
          } else {
            alert('Mordi')
            return
          }
          if (userInputs.pricepoint < 0.1) {
            alert('Pricepoint cannot be zero or negative')
            // return
          }
          //   await new Promise((r) => setTimeout(r, 500))
          //   alert(JSON.stringify(values, null, 2))
        }}
      >
        <Form>
          <br />
          <label htmlFor="cryptoid">Crypto: </label>
          <Select
            defaultValue={userInputs.cryptoid}
            // defaultValue={userInputs.cryptoid}
            style={{ width: '100%' }}
            onChange={(value) => dispatch(storeUserInputs('cryptoid', value))}
          >
            {Object.values(cryptos)
              .filter((item) => item.type === 'USD')
              .map((item) => (
                <Option value={item.id}>
                  {item.id} (Now: {item.currentValue} {item.type.toUpperCase()}, Trend now
                  (15min/30min/2h):{' '}
                  <span style={{ color: item.trend15min < 0 ? 'red' : 'green' }}>
                    {item.trend15min}%
                  </span>
                  /
                  <span style={{ color: item.trend30min < 0 ? 'red' : 'green' }}>
                    {item.trend30min}%
                  </span>
                  /
                  <span style={{ color: item.trend2hour < 0 ? 'red' : 'green' }}>
                    {item.trend2hour}%
                  </span>
                  )
                </Option>
              ))}
            {Object.values(cryptos)
              .filter((item) => item.type === 'nok')
              .map((item) => (
                <Option value={item.id}>
                  {item.id} (Now: {item.currentValue} {item.type.toUpperCase()})
                </Option>
              ))}
          </Select>
          <br />
          <label htmlFor="pricepoint">Pricepoint</label>
          userInputs.pricepoint: {userInputs.pricepoint}
          userInputs.direction: {userInputs.direction}{' '}
          {userInputs.direction === 'Please select' ? 'True' : 'False'}
          <Input
            type="number"
            id="pricepoint"
            name="pricepoint"
            placeholder={0}
            value={userInputs.pricepoint}
            onChange={(e) => dispatch(storeUserInputs(e.target.name, e.target.value))}
            suffix={userInputs.cryptoid}
          />
          <br />
          <label htmlFor="direction">Direction: </label>
          <Select
            defaultValue={userInputs.direction}
            style={{ width: 120 }}
            onChange={(value) => dispatch(storeUserInputs('direction', value))}
          >
            <Option value="up">Up</Option>
            <Option value="down">Down</Option>
            {/* <Option value="both">Both</Option> */} // FUTURE ADDITION
          </Select>
          <br />
        </Form>
      </Formik>
    </div>
  )
}

export default memo(CreatePriceAlert)
