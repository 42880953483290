const appvariablesReducer = (state = { DEBUG: false, headerMenuSelection: '1' }, action) => {
  switch (action.type) {
    case 'STOREAPPVARIABLES':
      let appvariables = { ...state.appvariables }
      appvariables[action.payload.name] = action.payload.value
      return Object.assign({}, state, {
        [action.payload.name]: action.payload.value
      })

    //   case FETCH_NEW_POSTS
    // let posts = [...state.posts]; // we're destructuring `state.posts` inside of array, essentially assigning the elements to a new array.
    // posts.push(action.payload.posts);
    // return {
    //     ...state,
    //     posts
    // };

    // case 'DECREMENT':
    //     return state - action.payload
    default:
      return state
  }
}

export default appvariablesReducer
