import styled from '@emotion/styled'
import { Button } from 'antd'
import { css } from '@emotion/core'

export const GraphWrapper = styled.div`
  width: 100%;
  margin: 0rem;
  font-size: 1.5em;
  text-align: center;
  padding: 0;
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
  /* grid-column: 1 / span 2; */
  /* margin-bottom: 3rem; */ // style: { height: '40vh', borderRadius: '2rem', marginTop: '21px' }
  .chartStyles {
    height: 20vh;
    border-radius: 2rem;
    margin-top: 21px;
    border: ${(props) => (props.offset ? `2px solid red` : `2px solid blue`)};
    @media only screen and (min-width: 1024px) {
      margin-top: 21px;
      border-radius: 2rem;
      /* height: ${(props) => (props.offset ? `40vh;` : `20vh;`)}; */
    }
  }
  ${(props) =>
    props.crypto === 'crypto:ethnok' &&
    `background: rgb(109,235,231);
background: radial-gradient(circle, rgba(109,235,231,1) 15%, rgba(148,155,233,1) 95%);`}

  ${(props) =>
    props.crypto === 'crypto:btcnok' &&
    `background: rgb(255,255,255);
background: radial-gradient(circle, rgba(255,255,255,1) 12%, rgba(255,178,52,1) 75%);`}

${(props) =>
    props.crypto === 'crypto:ltcnok' &&
    `background: rgb(217,222,249);
background: radial-gradient(circle, rgba(217,222,249,1) 0%, rgba(20,44,194,1) 100%);`}

${(props) =>
    props.crypto === 'crypto:xrpnok' &&
    `background: rgb(217,222,249);
background: radial-gradient(circle, rgba(217,222,249,1) 0%, rgba(105,120,212,1) 100%);`}

${(props) =>
    props.offset &&
    `background: rgb(217,222,249);
background: radial-gradient(circle, rgba(217,222,249,1) 0%, yellow 100%);`}


  hr {
    margin-top: 3rem;
    margin-bottom: 1rem;

    @media only screen and (min-width: 1024px) {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  p {
    color: black;
    font-size: 1rem;
    margin-bottom: 4px;
    margin-left: 2rem;
  }

  h3 {
    margin-bottom: 0;
  }
  h4 {
    font-size: 1rem;
  }

  button {
    margin-top: 1rem;
  }

  /* // Tablet:
  @media only screen and (min-width: 400px) {
    padding: 1rem;
  } */

  .stats {
    margin-bottom: 0;
  }

  // Tablet:
  @media only screen and (min-width: 768px) {
    padding: 1rem;
    padding-top: 0;
    padding-bottom: 0;
  }

  // Desktop:
  @media only screen and (min-width: 1024px) {
    /* grid-column: 1 / span 2; */
    /* padding: 5rem; */
    /* height: 100px; */
    /* grid-column: span 6; */
  }
`

export const Knapp = styled(Button)``
