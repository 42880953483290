import React, { memo, useEffect, useState } from 'react'
import { Formik } from 'formik'
import CryptoStatus from '../CryptoStatus/CryptoStatus'
import Dashboard from '../Dashboard/Dashboard'
import { Layout, Menu, Breadcrumb } from 'antd'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { storeUser, storeUserInputs, storeAppvariables } from '../../actions'
import { useSelector, useDispatch } from 'react-redux'
import AlexWebSocket from '../websocket'

const { Header, Content, Footer } = Layout

const HeaderComponent = () => {
  const selected = useSelector((state) => state.appvariables.headerMenuSelection)
  const dispatch = useDispatch()
  const [doInit, setDoInit] = useState(true)
  const appVariables = useSelector((state) => state.appvariables)

  const msgEventHandler = (message) => {
    let newData = message.data,
      id = message.id

    // console.log('msgEventhandler got: ', message)
    // console.log('newData?.title === graphs[0].title: ', message?.title, graphs[0].title, id)
    if (id === 'cryptos') {
      // console.log('HeaderComponent Got: ', newData)
      dispatch(storeAppvariables('cryptos', newData))
    } //else console.log(`HeaderComponent Received message from server: `, message)
  }

  useEffect(() => {
    try {
      if (doInit) {
        let socket = new AlexWebSocket('wss://linux.midteide.com/websocket') // using 5905
        socket.on('message', msgEventHandler)
        socket.on('close', () => setDoInit(true))
        window.AlexSocket = socket
        setDoInit(false)
      }
    } catch (error) {
      console.log('Error in UseEffect: ', error)
    }
  }, [doInit])

  return (
    <Layout>
      <Header style={{ zIndex: 1, width: '100%' }}>
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[selected]}>
          <Menu.Item key="home">
            <Link
              onClick={() => dispatch(storeAppvariables('headerMenuSelection', 'home'))}
              to={`/`}
            >
              Home
            </Link>
          </Menu.Item>
          <Menu.Item key="dashboard">
            <Link
              onClick={() => dispatch(storeAppvariables('headerMenuSelection', 'dashboard'))}
              to={`/dashboard`}
            >
              Dashboard
            </Link>
          </Menu.Item>
          <Menu.Item key="allusd">
            <Link
              onClick={() => dispatch(storeAppvariables('headerMenuSelection', 'allusd'))}
              to={`/allusd`}
            >
              ALL USD
            </Link>
          </Menu.Item>
          <Menu.Item key="allnok">
            <Link
              onClick={() => dispatch(storeAppvariables('headerMenuSelection', 'allnok'))}
              to={`/allnok`}
            >
              ALL NOK
            </Link>
          </Menu.Item>
        </Menu>
      </Header>
      {/* <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
    <Breadcrumb style={{ margin: '16px 0' }}>
      <Breadcrumb.Item>Main page</Breadcrumb.Item>
    </Breadcrumb>
    <CryptoStatus />
  </Content>
  <Footer style={{ textAlign: 'center' }}>©2021 Created by Alexander Midteide</Footer> */}
    </Layout>
  )
}

export default memo(HeaderComponent)
