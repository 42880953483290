import React, { memo, useEffect, useState } from 'react'
import { useField } from 'formik'
import { Table, Tag, Space, Button, Modal } from 'antd'
import { Formik, Field, Form } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { storeUser, storeUserInputs, storeAppvariables } from '../../actions'
import { formatNumber, getDateAsString } from '../Graph/GraphUtils'
import { ClickableText } from './styles'
import CreatePriceAlert from './CreatePriceAlert'
import CreateTrendAlert from './CreateTrendAlert'
import '../../App.css'
import { Typography } from 'antd'

const { Title } = Typography

var moment = require('moment-timezone')

const Notifications = ({ modalUser }) => {
  const user = useSelector((state) => state.user)
  console.log('user.pricealerts.pricealertnotifications', modalUser)

  const columns = [
    {
      title: 'Timestamp',
      dataIndex: 'createdAt',
      key: 'createdAt',
      // render: (text) => <a>{text} </a>
      render: (text) => <a>{moment.tz(text, 'Europe/Oslo').format('YYYY-MM-DD HH:mm:ss')}</a>
    },
    {
      title: 'Sent to email:',
      dataIndex: 'email',
      key: 'email',
      render: (text) => <a>{text}</a>
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      render: (text) => <a>{text}</a>
    }
  ]

  return <Table columns={columns} dataSource={modalUser} />
  return (
    <ul>
      {user.length &&
        user.map((item) => (
          <li key={item.id}>
            {moment.tz(item.createdAt, 'Europe/Oslo').format('YYYY-MM-DD HH:mm:ss')}, "
            {item.message}"{item.createdAt}
          </li>
        ))}
    </ul>
  )
}

const RenderTable = ({}) => {
  const DEBUG = useSelector((state) => state.appvariables.DEBUG)
  const [visible, setVisible] = useState(false)
  const [modaluser, setModaluser] = useState()
  const userInputs = useSelector((state) => state.userInputs)
  const user = useSelector((state) => state.user)
  const appVariables = useSelector((state) => state.appvariables)
  const dispatch = useDispatch()
  const cryptos = useSelector((state) => state.appvariables.cryptos)
  const [cryptosArr, setCryptosArr] = useState([])
  useEffect(() => {
    cryptos?.length && console.log('CryptoStatus, CRYPTOS: ', cryptosArr)
    try {
      Object.values(cryptos) && setCryptosArr(Object.values(cryptos))
    } catch (error) {}
  }, [cryptos])

  const deletePriceAlert = (id) => {
    let URL = 'https://linux.midteide.com/api/'
    let URLFull = URL + 'pricealerts/' + id

    fetch(URLFull, {
      crossDomain: true,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        username: DEBUG ? 'intercom' : userInputs.username,
        password: DEBUG ? 'heihei' : userInputs.password
      }
    })
      .then((response) => {
        return response.json()
      })
      .then((user) => {
        console.log('Update fetch got: ', user)
        dispatch(storeAppvariables('doFetchUser', true))
      })
      .catch((error) => {
        console.error('Dashboard Error:', error)
      })
  }

  const columnsPrice = [
    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
      // render: (text) => <a>{text} </a>
      render: (text) => <a>{moment.tz(text, 'Europe/Oslo').format('YYYY-MM-DD HH:mm:ss')}</a>
    },
    {
      title: 'Crypto',
      dataIndex: 'cryptoid',
      sorter: (a, b) => a.cryptoid.localeCompare(b.cryptoid),
      render: (text) => <a>{text}</a>
    },
    {
      title: 'Alert type',
      dataIndex: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (text) => <a>{text}</a>
    },
    {
      title: 'Price now',
      dataIndex: 'cryptoid',
      sorter: (a, b) =>
        parseFloat(a.trendpercentage ? a.trendpercentage : '0') -
        parseFloat(b.trendpercentage ? b.trendpercentage : '0'),
      render: (id, item) => (
        <a onClick={() => console.log(cryptos[id])}>
          <span
            style={{
              color:
                parseFloat(cryptos[item.cryptoid].currentValue) <
                parseFloat(cryptos[item.cryptoid].prevValue)
                  ? 'red'
                  : 'green'
            }}
          >
            {cryptos[id].currentValue}
          </span>
        </a>
      )
    },
    {
      title: 'Alert price',
      dataIndex: 'pricepoint',
      sorter: (a, b) =>
        parseFloat(a.pricepoint ? a.pricepoint : '0') -
        parseFloat(b.pricepoint ? b.pricepoint : '0'),
      render: (text, item) => (
        <a onClick={() => console.log('Item: ', item)}>
          {text
            ? `${formatNumber(parseFloat(text))}`
            : // ? `${parseFloat(text) > 500 ? formatNumber(parseFloat(text)) : item.pricepoint}`
              // ? `${parseFloat(text) > 500 ? formatNumber(parseFloat(text)) : parseFloat(text)}`
              'N/A'}
        </a>
      )
    },
    {
      title: 'Trend now (15min/30min/2h)',
      dataIndex: 'Trend',
      render: (text, item) => (
        <div>
          <span style={{ color: cryptos[item.cryptoid].trend15min < 0 ? 'red' : 'green' }}>
            {cryptos[item.cryptoid].trend15min}%
          </span>
          /
          <span style={{ color: cryptos[item.cryptoid].trend30min < 0 ? 'red' : 'green' }}>
            {cryptos[item.cryptoid].trend30min}%
          </span>
          /
          <span style={{ color: cryptos[item.cryptoid].trend2hour < 0 ? 'red' : 'green' }}>
            {cryptos[item.cryptoid].trend2hour}%
          </span>
        </div>
      )
    },
    {
      title: 'Trigger when going:',
      dataIndex: 'direction'
    },
    {
      title: 'Status',
      dataIndex: 'active',
      // render: (text) => <p>{text ? 'Active' : 'Disabled'}</p>
      render: (text, item) =>
        text ? (
          <ClickableText
            onClick={() => {
              updatePriceAlert(item.id, { active: false })
            }}
          >
            Active
          </ClickableText>
        ) : (
          <ClickableText
            onClick={() => {
              updatePriceAlert(item.id, { active: true })
            }}
          >
            Disabled
          </ClickableText>
        )
    },
    {
      title: 'Notifications',
      dataIndex: 'pricealertnotifications',
      sorter: (a, b) =>
        parseFloat(a.pricealertnotifications.length) - parseFloat(b.pricealertnotifications.length),
      render: (text) =>
        text.length ? (
          <ClickableText
            onClick={() => {
              setVisible('notifications')
              setModaluser(text)
            }}
          >
            {text.length}
          </ClickableText>
        ) : (
          'None'
        )
    },
    {
      title: 'Delete',
      dataIndex: 'id',
      render: (id) => <button onClick={() => deletePriceAlert(id)}>Delete</button>
    }
  ]

  const columnsTrend = [
    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
      // render: (text) => <a>{text} </a>
      render: (text) => <a>{moment.tz(text, 'Europe/Oslo').format('YYYY-MM-DD HH:mm:ss')}</a>
    },
    {
      title: 'Crypto',
      dataIndex: 'cryptoid',
      sorter: (a, b) => a.cryptoid.localeCompare(b.cryptoid),
      render: (text) => <a>{text}</a>
    },
    {
      title: 'Alert type',
      dataIndex: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (text) => <a>{text}</a>
    },
    {
      title: 'Trend trigger',
      dataIndex: 'trendpercentage',
      sorter: (a, b) =>
        parseFloat(a.trendpercentage ? a.trendpercentage : '0') -
        parseFloat(b.trendpercentage ? b.trendpercentage : '0'),
      render: (text) => <a>{text ? `${text}%` : 'N/A'}</a>
    },
    {
      title: 'Trend now (15min/30min/2h)',
      dataIndex: 'Trend',
      render: (text, item) => (
        <div>
          <span style={{ color: cryptos[item.cryptoid].trend15min < 0 ? 'red' : 'green' }}>
            {cryptos[item.cryptoid].trend15min}%
          </span>
          /
          <span style={{ color: cryptos[item.cryptoid].trend30min < 0 ? 'red' : 'green' }}>
            {cryptos[item.cryptoid].trend30min}%
          </span>
          /
          <span style={{ color: cryptos[item.cryptoid].trend2hour < 0 ? 'red' : 'green' }}>
            {cryptos[item.cryptoid].trend2hour}%
          </span>
        </div>
      )
    },
    {
      title: 'Trigger when going:',
      dataIndex: 'direction'
    },
    {
      title: 'Status',
      dataIndex: 'active',
      // render: (text) => <p>{text ? 'Active' : 'Disabled'}</p>
      render: (text, item) =>
        text ? (
          <ClickableText
            onClick={() => {
              updatePriceAlert(item.id, { active: false })
            }}
          >
            Active
          </ClickableText>
        ) : (
          <ClickableText
            onClick={() => {
              updatePriceAlert(item.id, { active: true })
            }}
          >
            Disabled
          </ClickableText>
        )
    },
    {
      title: 'Notifications',
      dataIndex: 'pricealertnotifications',
      sorter: (a, b) =>
        parseFloat(a.pricealertnotifications.length) - parseFloat(b.pricealertnotifications.length),
      render: (text) =>
        text.length ? (
          <ClickableText
            onClick={() => {
              setVisible('notifications')
              setModaluser(text)
            }}
          >
            {text.length}
          </ClickableText>
        ) : (
          'None'
        )
    },
    {
      title: 'Delete',
      dataIndex: 'id',
      render: (id) => <button onClick={() => deletePriceAlert(id)}>Delete</button>
    }
  ]

  const createPriceAlert = () => {
    console.log(
      'Create got: ',
      userInputs.username,
      parseFloat(userInputs.pricepoint),
      userInputs,
      userInputs.cryptoid,
      userInputs.direction
    )

    let URL = 'https://linux.midteide.com/api/'
    let URLFull = URL + 'pricealerts/'

    fetch(URLFull, {
      crossDomain: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        username: DEBUG ? 'intercom' : userInputs.username,
        password: DEBUG ? 'heihei' : userInputs.password
      },
      body: JSON.stringify({
        password: DEBUG ? 'heihei' : userInputs.password,
        pricepoint: parseFloat(userInputs.pricepoint),
        direction: userInputs.direction,
        cryptoid: userInputs.cryptoid,
        type: 'price'
      })
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((data) => {
        console.log('Create: Fetched data (', data)
        dispatch(storeAppvariables('doFetchUser', true))
      })
      .catch((error) => {
        console.error('Dashboard Create Error:', error)
      })
  }

  const createTrendAlert = () => {
    console.log('CREATE TREND ALERT:')
    console.log('userInputs.username: ', userInputs.username)
    console.log('userInputs.trendpercentage: ', userInputs.trendpercentage)
    console.log('userInputs.cryptoid: ', userInputs.cryptoid)
    console.log('userInputs.alertType: ', userInputs.alertType)

    let URL = 'https://linux.midteide.com/api/'
    let URLFull = URL + 'pricealerts/'

    fetch(URLFull, {
      crossDomain: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        username: DEBUG ? 'intercom' : userInputs.username,
        password: DEBUG ? 'heihei' : userInputs.password
      },
      body: JSON.stringify({
        password: DEBUG ? 'heihei' : userInputs.password,
        trendpercentage: parseFloat(userInputs.trendpercentage),
        type: userInputs.alertType,
        direction: userInputs.direction,
        cryptoid: userInputs.cryptoid
      })
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((data) => {
        console.log('Create: Fetched data (', data)
        dispatch(storeAppvariables('doFetchUser', true))
      })
      .catch((error) => {
        console.error('Dashboard createTrendAlert Error:', error)
      })
  }

  const updatePriceAlert = (id, updateProps) => {
    console.log('Updating, userInputs is now: ', userInputs, id)

    let bodyTemp = Object.assign(
      {},
      {
        username: userInputs.username
      },
      updateProps
    )
    console.log('bodyTemp now looks like: ', bodyTemp)

    let URL = 'https://linux.midteide.com/api/'
    let URLFull = URL + 'pricealerts/' + id

    fetch(URLFull, {
      crossDomain: true,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        username: DEBUG ? 'intercom' : userInputs.username,
        password: DEBUG ? 'heihei' : userInputs.password
      },
      body: JSON.stringify(bodyTemp)
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((data) => {
        console.log('Update: Fetched data (', data)
        dispatch(storeAppvariables('doFetchUser', true))
      })
      .catch((error) => {
        console.error('Dashboard Update Error:', error)
      })
  }
  const renderModal = () => {
    switch (visible) {
      case 'notifications':
        return (
          <Modal
            title="Notifications sent for this particular Price Alert:"
            centered
            visible={visible}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            width={1000}
          >
            <Notifications modalUser={modaluser} />
          </Modal>
        )
      case 'create':
        // dispatch(storeUserInputs('alertType', 'price'))

        return (
          <Modal
            title="Create new price alert"
            centered
            visible={visible}
            onOk={() => {
              if (userInputs.direction === 'Select...') {
                alert('Please select direction')
                return
              }
              if (userInputs.cryptoid === 'Select...') {
                alert('Please select crypto currency')
                return
              }
              if (userInputs.pricepoint < 0.001) {
                alert('Pricepoint cannot be zero or negative')
                return
              }

              console.log('Creating: ', userInputs)
              createPriceAlert()
              setVisible(false)
            }}
            onCancel={() => setVisible(false)}
            width={1000}
          >
            <CreatePriceAlert user={user} />
          </Modal>
        )
      case 'createTrendAlert':
        return (
          <Modal
            title="Create new trend alert"
            centered
            visible={visible}
            onOk={() => {
              if (userInputs.direction === 'Select...') {
                alert('Please select direction')
                return
              }
              if (userInputs.cryptoid === 'Select...') {
                alert('Please select crypto currency')
                return
              }
              if (userInputs.alertType === 'Select...') {
                alert('Please select time frame')
                return
              }
              if (userInputs.trendpercentage < 0.001) {
                alert('Percentage value cannot be zero or negative')
                return
              }

              console.log('Creating: ', userInputs)
              createTrendAlert()
              setVisible(false)
            }}
            onCancel={() => setVisible(false)}
            width={1000}
          >
            <CreateTrendAlert user={user} />
          </Modal>
        )
      default:
        return <div style={{ padding: '4rem' }}></div>
    }
  }

  return (
    <div>
      <Title style={{ paddingTop: '1rem' }} level={2}>
        Price alerts:
      </Title>
      <Table
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30']
        }}
        columns={columnsPrice}
        dataSource={
          user.pricealerts?.length
            ? user.pricealerts.filter((item) => item.type === 'price')
            : user.pricealerts
        }
      />
      <Button
        type="primary"
        style={{ marginBottom: '2rem' }}
        disabled={user.id ? false : true}
        onClick={() => setVisible('create')}
      >
        Create new price alert
      </Button>
      <Title level={2}>Trend alerts:</Title>
      <Table
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30']
        }}
        columns={columnsTrend}
        dataSource={
          user.pricealerts?.length
            ? user.pricealerts.filter((item) => item.type !== 'price')
            : user.pricealerts
        }
      />
      <Button
        type="primary"
        style={{ margin: '2rem' }}
        disabled={user.id ? false : true}
        onClick={() => setVisible('createTrendAlert')}
      >
        Create new trend alert
      </Button>
      {renderModal()}
    </div>
  )
}

export default memo(RenderTable)
