import React, { memo, useEffect, useState, useRef } from 'react'
import Highcharts, { dateFormat, getDeferredAnimation } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { GraphWrapper } from './styles'
import { Button } from 'antd'

const SHOW_ALL_DATA = 'logentries'
const SHOW_LAST_WEEK = 'logentries/temp_week'
const SHOW_LAST_DAY = 'logentries/temp_day'
const SHOW_LAST_MONTH = 'logentries/temp_month'
const SHOW_LAST_YEAR = 'logentries/temp_year'
const SHOW_HOURS = 'logentries/entries_hours'

export const getWeekdayNorwegian = (dayNumber) => {
  switch (dayNumber) {
    case 0:
      return 'Søn'
    case 1:
      return 'Man'
    case 2:
      return 'Tirs'
    case 3:
      return 'Ons'
    case 4:
      return 'Tors'
    case 5:
      return 'Fre'
    case 6:
      return 'Lør'
  }
}
export const getWeekday = (dayNumber) => {
  switch (dayNumber) {
    case 0:
      return 'Sun'
    case 1:
      return 'Mon'
    case 2:
      return 'Tue'
    case 3:
      return 'Wed'
    case 4:
      return 'Thur'
    case 5:
      return 'Fri'
    case 6:
      return 'Sat'
  }
}
export const getMonthName = (monthNumber) => {
  switch (monthNumber) {
    case 1:
      return 'Jan'
    case 2:
      return 'Feb'
    case 3:
      return 'Mar'
    case 4:
      return 'Apr'
    case 5:
      return 'May'
    case 6:
      return 'Jun'
    case 7:
      return 'Jul'
    case 8:
      return 'Aug'
    case 9:
      return 'Sep'
    case 10:
      return 'Oct'
    case 11:
      return 'Nov'
    case 12:
      return 'Dec'
  }
}

// export const getAverage = (entries) => {
//   const debug = false
//   // const [avg, setAvg] = useState(0)
//   let timeBetween = 0 //(entries[entries.length - 1][0] - entries[entries.length - 2][0]) / 1000 / 60
//   let operations = 0,
//     tempAcu = 0,
//     avg = 0
//   for (let i = 1; i < entries.length; i++) {
//     timeBetween += (entries[i][0] - entries[i - 1][0]) / 1000.0 / 60.0 / 60.0 / 24.0
//     operations++
//     tempAcu += entries[i - 1][1]
//   }
//   for (let i = 1; i < entries.length; i++) {
//     avg +=
//       (entries[i - 1][1] * (entries[i][0] - entries[i - 1][0])) /
//       1000.0 /
//       60.0 /
//       60.0 /
//       (24.0 * timeBetween)
//   }
//   tempAcu /= operations
//   // setAvg()
//   if (debug) {
//     console.log('Average: ', tempAcu)
//     console.log('Average 2 : ', avg)
//     console.log('timeBetween', timeBetween)
//     console.log('Ops: ', operations, 'length: ', entries.length, 'tempAcu: ', tempAcu)
//   }
//   return avg
// }

// const getStartDate = (logEntries) => stringToDateUTC(logEntries[0][0].createdAt)
const getStartDate = (offset, numberOfHours) => {
  return getEndDate(offset) - numberOfHours * 60 * 60 * 1000
}

const getEndDate = (offset = 0) => {
  let d = new Date()
  d.setTime(d.getTime() + (getSummerTimeDifference() - offset) * 60 * 60 * 1000)
  return d.getTime()
}

export const plotGraph = (logEntries, chartData, numberofhours, offset) => {
  let arr = logEntries[logEntries.length - 1]?.length ? [...logEntries[0]] : []
  let currency = arr[arr.length - 1].type.toUpperCase()
  return {
    chart: {
      scrollablePlotArea: {
        // minWidth: 700,
      },
      zoomType: 'x',
      zoomKey: 'x'
    },
    title: {
      text: ''
      // Text moved to outside of graph ||
    },

    subtitle: {
      text: ''
    },
    xAxis: {
      type: 'datetime',
      //Sets tickInterval to 24 * 3600 * 1000 if display is by day
      dateTimeLabelFormats: {
        hour: '%H'
      },
      tickInterval: Math.round((1000 * (3600.0 / 24.0) * parseFloat(numberofhours)) / 2), // tick every  hour
      min: getStartDate(offset, numberofhours), // + getSummerTimeDifference() * 60 * 60 * 1000,
      max: getEndDate(offset)

      // startOnTick: true,
      // endOnTick: true,
    },

    yAxis: {
      title: {
        text: currency
      }
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      area: {
        fillOpacity: 0.1,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0.1).get('rgba')]
          ]
        },
        marker: {
          radius: 2
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        threshold: null
      }
    },

    series: chartData,
    turboThreshold: 20000
  }
}

export const stringToDateUTC = (dateString) => {
  let retValue = 0

  let raw = dateString
  let rawSplit = raw.split('-')
  let year = parseInt(rawSplit[0])
  let month = parseInt(rawSplit[1] - 1)
  let t = rawSplit[2].split('T')
  let day = parseInt(t[0])
  t = t[1].split(':')
  let hour = parseInt(t[0]) + getSummerTimeDifference()
  if (hour > 23) {
    hour -= 24
    day++
    // console.log('HOUR IS: ', hour)
  }
  let minute = t[1]
  let second = t[2].split('.')[0]
  // console.log('Was: ', dateString, getSummerTimeDifference())
  // let finalDate = stringToDateUTC(entry.createdAt) + getSummerTimeDifference() * 60 * 60 * 1000
  // console.log('stingToDateUTC: ', dateString, year, month, day, hour, minute, second)
  retValue = Date.UTC(year, month, day, hour, minute, second)
  // console.log('Done date: ', year, month, day, hour, minute, second, retValue)
  return retValue
}
const usePrevious = (value, initialValue) => {
  const ref = useRef(initialValue)
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
export const useEffectDebugger = (effectHook, dependencies, dependencyNames = []) => {
  const previousDeps = usePrevious(dependencies, [])

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency
        }
      }
    }

    return accum
  }, {})

  if (Object.keys(changedDeps).length) {
    // console.log('[use-effect-debugger] ', changedDeps)
  }

  useEffect(effectHook, dependencies)
}
export const getDateAsString = (date) => {
  const weekDay = getWeekday(new Date(date).getDay())
  const year = new Date(date).getFullYear().toString()
  const month = getMonthName(new Date(date).getMonth() + 1)
  const day = new Date(date).getDate().toString()
  const hour = ('0' + new Date(date).getUTCHours()).slice(-2)
  const minutes = ('0' + new Date(date).getMinutes()).slice(-2)
  const seconds = ('0' + new Date(date).getSeconds()).slice(-2)
  return `${weekDay} ${day}. ${month} ${year} kl.: 
  ${hour}:${minutes}:${seconds}`
}

export const fetchLogEntries = (
  showDataFromTimePeriod,
  setFetchComplete,
  graphs,
  setlogEntries,
  URL,
  hours,
  offset = 0,
  setEntriesStats
) => {
  // console.log(
  //   'FETCHing : ',
  //   new Date().toLocaleDateString('no', { hour: 'numeric', minute: 'numeric', second: 'numeric' }),
  //   'hours: ',
  //   hours
  // )
  let tempArray = []
  setFetchComplete(false)
  for (let i = 0; i < graphs.length; i++) {
    let URLFull = URL + showDataFromTimePeriod
    fetch(URLFull, {
      crossDomain: true,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        type: graphs[i].type,
        title: graphs[i].title,
        numberofhours: hours,
        offset: offset
      }
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        // console.log('Fetched data: ', data)
        let dataSorted = data.entries
          .filter((item) => item.id)
          .sort((a, b) => (a.id > b.id ? 1 : -1))
        tempArray.push(data.entries)
        setEntriesStats({
          averageValue: data.stats.averageValue,
          previousAverageValue: data.stats.previousAverageValue
        })
        // tempArray.push(data.entries.filter((item) => item.id))
        setlogEntries(tempArray)
        if (i === graphs.length - 1) setFetchComplete(true)
        if (graphs[i].type === 'digital') console.log('DIGITAL: ', data)
        // console.log('tempArray now looks like: ', tempArray)
      })
      .catch((error) => {
        console.error('LogEntries Error:', error)
      })
  }
}

function sleep(time, callback) {
  var stop = new Date().getTime()
  while (new Date().getTime() < stop + time) {}
  callback()
}
const IntTwoChars = (i) => {
  return `0${i}`.slice(-2)
}
const getCurrentTime = () => {
  let date_ob = new Date()
  let date = IntTwoChars(date_ob.getDate())
  let month = IntTwoChars(date_ob.getMonth() + 1)
  let year = date_ob.getFullYear()
  let hours = IntTwoChars(date_ob.getHours())
  let minutes = IntTwoChars(date_ob.getMinutes())
  let seconds = IntTwoChars(date_ob.getSeconds())
  let dateDisplay = `${year}/${month}/${date} ${hours}:${minutes}:${seconds} `
  return dateDisplay
}

export const logg = (message) => console.log(getCurrentTime(), message)

export const getUpdate = (logEntries, setlogEntries, URL, graphs) => {
  // console.log(
  //   'FETCHing updates : ',
  //   new Date().toLocaleDateString('no', { hour: 'numeric', minute: 'numeric', second: 'numeric' })
  // )
  console.log('getUpdate args: ', logEntries, setlogEntries, URL, graphs)
  let arr = logEntries[logEntries.length - 1]?.length ? [...logEntries[0]] : []

  let URLFull = URL + 'logentries/entries_since'
  fetch(URLFull, {
    crossDomain: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      type: graphs[0].type,
      title: graphs[0].title,
      sincedate: arr.length ? arr[arr.length - 2].createdAt : ''
    }
  })
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      console.log('Update fetch got: ', data)
      if (data.length) {
        let dataSorted = data.sort((a, b) => (a.id > b.id ? 1 : -1))
        // let alex = Object.assign(logEntries[0], dataSorted)
        const newLogEntries = [[...logEntries[0], ...dataSorted], ...logEntries.slice(1)]
        console.log(
          `In getUpdate: Got ${dataSorted.length} new entries:`,
          dataSorted,
          'logEntries length was: ',
          logEntries[0].length,
          ' but new logEntries will be ',
          newLogEntries.length
        )
        setlogEntries(newLogEntries)
      }
    })
    .catch((error) => {
      console.error('LogEntries Error:', error)
    })
}

export const formatNumber = (number) =>
  number.toLocaleString(navigator.language, {
    maximumFractionDigits: 2
  })
// .split(',')
// .join(' ')

export const getSummerTimeDifference = () => parseInt(new Date().toString().split('GMT+0')[1][0])

export const getData = (graphs, logEntries, setWarmestPoint, setColdestPoint, offset) => {
  let retArray = []

  for (let i = 0; i < graphs.length; i++) {
    let temp = logEntries[i].filter(
      (item) => item.title === graphs[i].title // && stringToDateUTC(item.createdAt) > getStartDate(offset)
    )
    // console.log('TEMP LENGTH NOW: ', temp.length)
    let coldest = [new Date(), 9999999],
      warmest = [new Date(), -9999999]
    let tempFiltered = temp.map((entry, index) => {
      let finalDate = stringToDateUTC(entry.createdAt) //+ getSummerTimeDifference() * 60 * 60 * 1000

      let retValue =
        graphs[i].type === 'digital'
          ? entry.value === 'True'
            ? 10.0
            : 0.0
          : parseFloat(entry.value)
      if (i === 0) {
        if (retValue < coldest[1]) {
          coldest = [finalDate, retValue]
        }
        if (retValue > warmest[1]) {
          warmest = [finalDate, retValue]
        }
      }
      return [finalDate, retValue]
    })
    if (i === 0) {
      setWarmestPoint(warmest)
      setColdestPoint(coldest)
    }
    try {
      retArray.push({
        type: 'area',
        data: tempFiltered,
        name: graphs[i].name ? graphs[i].name : temp[0].description,
        ...(i !== 0 && { color: graphs[i].color }),
        ...(i !== 0 && { fillColor: graphs[i].fillColor })
        // fillColor: i !== 0 && graphs[i].fillColor,
      })
    } catch (error) {
      console.log(error)
    }
  }
  return retArray
}
