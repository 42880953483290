import React, { useEffect, useState, memo } from 'react'
import Header from '../HeaderComponent/HeaderComponent'
import { useSelector, useDispatch } from 'react-redux'
import { storeUser, storeUserInputs, storeAppvariables } from '../../actions'
import { Table, Tag, Space, Button, Modal } from 'antd'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { formatNumber } from '../Graph/GraphUtils'

const CryptoStatus = () => {
  const dispatch = useDispatch()
  const [pricepointInput, setPricePointInput] = useState(0)
  const [directionInput, setDirectionInput] = useState('up')
  const [cryptoidInput, setCryptoidInput] = useState('BTCBUSD')
  const userInputs = useSelector((state) => state.userInputs)
  const DEBUG = useSelector((state) => state.appvariables.debug)
  const cryptos = useSelector((state) => state.appvariables.cryptos)
  const [cryptosArr, setCryptosArr] = useState([])
  useEffect(() => {
    cryptos?.length && console.log('CryptoStatus, CRYPTOS: ', cryptosArr)
    try {
      Object.values(cryptos) && setCryptosArr(Object.values(cryptos))
    } catch (error) {}
  }, [cryptos])

  const columns = [
    {
      title: 'Crypto id',
      dataIndex: 'id',
      key: 'id',
      render: (text, item) => (
        <Link to={`/${item.id}`}>
          <p href>{text} </p>
        </Link>
      ),
      sorter: (a, b) => a.id.localeCompare(b.id),
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend']
      // render: (text) => <a>{moment.tz(text, 'Europe/Oslo').format('YYYY-MM-DD hh:mm:ss')}</a>
    },
    {
      title: 'Crypto description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => <p>{text}</p>
    },
    {
      title: 'Current price',
      dataIndex: 'currentValue',
      key: 'currentValue',
      render: (text, item) => (
        <p>
          {formatNumber(parseFloat(text))} {item.type.toUpperCase()}
        </p>
      )
    },
    {
      title: 'Trend last 15 min',
      dataIndex: 'trend15min',
      key: 'trend15min',
      sorter: (a, b) => parseFloat(a.trend15min) - parseFloat(b.trend15min),
      render: (text, item) => (
        <p style={{ color: parseFloat(text) < 0 ? 'red' : ' green' }}>{text ? `${text}%` : '-'}</p>
      )
    },
    {
      title: 'Trend last 30 min',
      dataIndex: 'trend30min',
      key: 'trend30min',
      sorter: (a, b) => parseFloat(a.trend30min) - parseFloat(b.trend30min),
      render: (text, item) => (
        <p style={{ color: parseFloat(text) < 0 ? 'red' : ' green' }}>{text ? `${text}%` : '-'}</p>
      )
    },

    {
      title: 'Trend last 2 hours',
      dataIndex: 'trend2hour',
      key: 'trend2hour',
      sorter: (a, b) => parseFloat(a.trend2hour) - parseFloat(b.trend2hour),
      render: (text, item) => (
        <p style={{ color: parseFloat(text) < 0 ? 'red' : ' green' }}>{text ? `${text}%` : '-'}</p>
      )
    }
  ]

  return (
    <div>
      <h2 style={{ textAlign: 'center', paddingTop: '2rem' }}>Cryptos currently available:</h2>
      <Table
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30']
        }}
        style={{ padding: '0 2rem' }}
        columns={columns}
        dataSource={cryptosArr.filter((item) => item.type === 'USD')}
      />
      <h2 style={{ textAlign: 'center', paddingTop: '2rem' }}>NOK Cryptos:</h2>
      <Table
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30']
        }}
        style={{ padding: '0 2rem' }}
        columns={columns}
        dataSource={cryptosArr.filter((item) => item.type === 'nok')}
      />
    </div>
  )
}

export default memo(CryptoStatus)
