import React, { useEffect, useState, memo } from 'react'
import { DashboardWrapper } from './styles'
import RenderTable from './Table'
import { Formik, Field, Form } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { storeUser, storeUserInputs, storeAppvariables } from '../../actions'
import HeaderComponent from '../HeaderComponent/HeaderComponent'
import '../../App.css'
import { Layout, Menu, Breadcrumb } from 'antd'

// DEBUG FLAG SETTING HERE:
const debug = false
const { Header, Content, Footer } = Layout

const Dashboard = () => {
  const counter = useSelector((state) => state.counter)
  const user = useSelector((state) => state.user)
  const userInputs = useSelector((state) => state.userInputs)
  const appVariables = useSelector((state) => state.appvariables)
  const dispatch = useDispatch()
  const DEBUG = useSelector((state) => state.appvariables.DEBUG)

  useEffect(() => {
    if (appVariables.doFetchUser) {
      dispatch(storeAppvariables('doFetchUser', false))
      let URL = 'https://linux.midteide.com/api/'
      let URLFull = URL + 'users'

      fetch(URLFull, {
        crossDomain: true,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          username: DEBUG ? 'intercom' : userInputs.username,
          password: DEBUG ? 'heihei' : userInputs.password
        }
      })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          console.log('Update fetch got: ', typeof data, data)
          dispatch(storeUser(data))
        })
        .catch((error) => {
          console.error('Dashboard Error:', error)
        })
    }
  }, [appVariables])

  useEffect(() => {
    dispatch(storeAppvariables('DEBUG', debug))
    console.log('DEBUG now: ', debug)
  }, [])

  return (
    <DashboardWrapper>
      {/* DashboardWrapper.js userInputs: {JSON.stringify(userInputs)}
      <br />
      DashboardWrapper.js user: {JSON.stringify(user)}
      <br />
      DashboardWrapper.js appVariables: {JSON.stringify(appVariables)}
      <div className="Redux">
        <button onClick={() => dispatch(storeUserInputs('username', 'intercom'))}>
          Save input with username
        </button>
        <button onClick={() => dispatch(storeUserInputs('password', 'asdasd'))}>
          Save input with password
        </button>
        
      </div> */}
      <HeaderComponent />
      <Content style={{ marginLeft: '1rem', marginTop: '1rem' }}>
        <Formik
          initialValues={{
            username: '',
            password: ''
          }}
          onSubmit={async (values) => {
            if (userInputs.username !== '' && userInputs.password !== '')
              dispatch(storeAppvariables('doFetchUser', true))
            else alert('Username/password fields can not be empty.')
          }}
        >
          <Form>
            <label style={{ marginRight: '0.5rem' }} htmlFor="username">
              Username
            </label>
            <Field
              id="username"
              name="username"
              placeholder="Username"
              value={userInputs.username}
              onChange={(e) => dispatch(storeUserInputs(e.target.name, e.target.value))}
            />
            <label style={{ marginLeft: '1rem', marginRight: '0.5rem' }} htmlFor="password">
              Password
            </label>
            <Field
              id="password"
              type="password"
              style={{ marginRight: '4px' }}
              name="password"
              placeholder="Password"
              value={userInputs.password}
              onChange={(e) => dispatch(storeUserInputs(e.target.name, e.target.value))}
            />
            <button type="submit">Log in</button>
          </Form>
        </Formik>
        {user.id ? <RenderTable /> : <h3 style={{ paddingTop: '4rem' }}>Please log in.</h3>}
      </Content>
      {/* <ul>{data.length && data.map((item) => <li>{item.pricepoint}</li>)}</ul> */}
    </DashboardWrapper>
  )
}

export default memo(Dashboard)
