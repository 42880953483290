import React, { useEffect, useState } from 'react'
import Graph from './components/Graph/Graph'
import 'antd/dist/antd.css'
import { AppContainer, AppWrapper } from './styles'
import { Typography, Divider } from 'antd'
import Header from './components/HeaderComponent/HeaderComponent'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import './App.css'
import Dashboard from './components/Dashboard/Dashboard'
import AllUsd from './components/pages/AllUsd'
import { useSelector, useDispatch } from 'react-redux'
import { storeUser, storeUserInputs, storeAppvariables } from './actions'
import CryptoStatus from './components/CryptoStatus/CryptoStatus'
import Main from './components/pages/Main'
import AllNok from './components/pages/AllNok'

const beerLoggerURL = 'https://1h8op8bex9.execute-api.eu-west-1.amazonaws.com/deploytest/logentries'

const weatherLoggerURL =
  'http://logentryserver-env.eba-mmrtycn6.eu-west-1.elasticbeanstalk.com/api/logentries'

const nodeApiGatewayURL = 'https://linux.midteide.com/api/'

function App() {
  const logoHeight = '3rem'
  const [cryptos, setCryptos] = useState({})
  const appVariables = useSelector((state) => state.appvariables)
  const dispatch = useDispatch()
  useEffect(() => {
    const URLFull = 'https://linux.midteide.com/api/' + 'logentries/cryptos'
    console.log('Fetchingfrom: ', URLFull)
    fetch(URLFull, {
      crossDomain: true,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        crypto: 'ALL'
      }
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        console.log('App Fetched cryptos: ', data)
        setCryptos(data)
        dispatch(storeAppvariables('cryptos', data))
        // let dataSorted = data.entries
        //   .filter((item) => item.id)
        //   .sort((a, b) => (a.id > b.id ? 1 : -1))
      })
      .catch((error) => {
        console.error('AllUsd Error:', error)
      })
  }, [])

  return (
    <Router>
      <AppWrapper className="App">
        {/* <Header /> */}
        <Switch>
          <Route path="/allnok">
            <AppContainer>
              <AllNok URL={nodeApiGatewayURL} />
            </AppContainer>
          </Route>

          <Route path="/allusd">
            <AllUsd URL={nodeApiGatewayURL} />
          </Route>

          {Object.values(cryptos).map((item) => (
            <Route path={`/${item.id}`} key={item.id}>
              <Graph
                graphs={[
                  {
                    title: item.title,
                    type: item.type
                  }
                ]}
                URL={nodeApiGatewayURL}
              />
            </Route>
          ))}
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/">
            <Main />
            {/* <div>
              <ul
                style={{
                  margin: '0 auto',
                  width: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  listStyleType: 'none',
                  flexDirection: 'column'
                }}
              >
                <li style={{ margin: '2rem' }}>
                  <Link to="/xrp">
                    <img
                      src="https://cdn.worldvectorlogo.com/logos/ripple.svg"
                      style={{ maxHeight: logoHeight, maxHeight: '4rem' }}
                    />
                  </Link>
                </li>
                <li style={{ margin: '2rem' }}>
                  <Link to="/btc">
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Bitcoin_logo.svg/1280px-Bitcoin_logo.svg.png"
                      style={{ maxHeight: logoHeight }}
                    />
                  </Link>
                </li>
                <li style={{ margin: '2rem', maxHeight: '4rem' }}>
                  <Link to="/ltc">
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/6_Full_Logo_S-2.png/1599px-6_Full_Logo_S-2.png"
                      style={{ maxHeight: logoHeight, maxHeight: '4rem' }}
                    />
                  </Link>
                </li>
                <li style={{ margin: '2rem' }}>
                  <Link to="/eth">
                    <img
                      src="https://ethereum.org/static/8ea7775026f258b32e5027fe2408c49f/31987/ethereum-logo-landscape-black.png"
                      style={{ minHeight: logoHeight, maxHeight: '4rem' }}
                    />
                  </Link>
                </li>
              </ul>
            </div> */}
          </Route>
        </Switch>
        {/* <Graph
            graphs={[
              {
                title: 'crypto:xrpnok',
                type: 'nok',
                color: 'rgba(0,0,255,1)',
                fillColor: 'rgba(0,0,255,0.2)'
              }
            ]}
            URL={nodeApiGatewayURL}
          /> */}
        {/* </AppContainer> */}
      </AppWrapper>
    </Router>
  )
}

export default App

// createdAt: "2020-10-11T23:32:43.403Z"
// description: "Description will be here..."
// id: 606
// title: "Humidity outside"
// type: "humidity"
// updatedAt: "2020-10-11T23:32:43.403Z"
// value: "52.47"
// __proto__: Object
