import React, { useEffect, useState, memo } from 'react'
import Graph from '../Graph/Graph'
import HeaderComponent from '../HeaderComponent/HeaderComponent'

const AllUsd = ({ URL }) => {
  const [cryptos, setCryptos] = useState({})

  useEffect(() => {
    const URLFull = URL + 'logentries/cryptos'
    console.log('Fetchingfrom: ', URLFull)
    fetch(URLFull, {
      crossDomain: true,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        crypto: 'USD'
      }
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        console.log('Fetched cryptos: ', typeof data)
        setCryptos(data)
        // let dataSorted = data.entries
        //   .filter((item) => item.id)
        //   .sort((a, b) => (a.id > b.id ? 1 : -1))
      })
      .catch((error) => {
        console.error('AllUsd Error:', error)
      })
  }, [])

  const renderGraphs = () => {
    console.log(Object.values(cryptos))
    if (cryptos)
      return (
        <div>
          {Object.values(cryptos).map((item) => (
            <Graph
              graphs={[
                {
                  title: item.title,
                  type: item.type
                }
              ]}
              URL={URL}
            />
          ))}
        </div>
      )
    else return <p>None</p>
  }

  return (
    <div>
      <HeaderComponent />
      {renderGraphs()} <p>{cryptos.length}</p>
    </div>
  )
}

export default memo(AllUsd)
