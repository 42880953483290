import React, { memo, useEffect, useState, useRef } from 'react'
import { Button } from 'antd'
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  HomeOutlined,
  ZoomInOutlined,
  ZoomOutOutlined
} from '@ant-design/icons'

const GraphButtonsHours = ({
  numberOfHours,
  setNumberOfHours,
  setOffset,
  offset,
  largeGraph,
  setLargeGraph
}) => {
  return (
    <div>
      <Button
        style={{ backgroundColor: 'green', borderColor: 'green' }}
        type="danger"
        shape="circle"
        size="small"
        icon={<CaretLeftOutlined />}
        onClick={() => setOffset((prev) => prev + numberOfHours)}
      />
      <Button
        type={numberOfHours === 0.5 ? 'primary' : 'default'}
        shape="round"
        size="small"
        onClick={() => setNumberOfHours(0.5)}
      >
        30min
      </Button>
      <Button
        type={numberOfHours === 1 ? 'primary' : 'default'}
        shape="round"
        size="small"
        onClick={() => setNumberOfHours(1)}
      >
        60min
      </Button>
      <Button
        type={numberOfHours === 4 ? 'primary' : 'default'}
        shape="round"
        size="small"
        onClick={() => setNumberOfHours(4)}
      >
        4t
      </Button>
      <Button
        type={numberOfHours === 12 ? 'primary' : 'default'}
        shape="round"
        size="small"
        onClick={() => setNumberOfHours(12)}
      >
        12t
      </Button>
      <Button
        type={numberOfHours === 24 ? 'primary' : 'default'}
        shape="round"
        size="small"
        onClick={() => setNumberOfHours(24)}
      >
        1d
      </Button>
      <Button
        type={numberOfHours === 48 ? 'primary' : 'default'}
        shape="round"
        size="small"
        onClick={() => setNumberOfHours(48)}
      >
        2d
      </Button>
      <Button
        type={numberOfHours === 72 ? 'primary' : 'default'}
        shape="round"
        size="small"
        onClick={() => setNumberOfHours(72)}
      >
        3d
      </Button>
      <Button
        type={numberOfHours === 168 ? 'primary' : 'default'}
        shape="round"
        size="small"
        onClick={() => setNumberOfHours(168)}
      >
        1w
      </Button>
      <Button
        type={numberOfHours === 336 ? 'primary' : 'default'}
        shape="round"
        size="small"
        onClick={() => setNumberOfHours(336)}
      >
        2w
      </Button>
      <Button
        type={numberOfHours === 672 ? 'primary' : 'default'}
        shape="round"
        size="small"
        onClick={() => setNumberOfHours(672)}
      >
        4w
      </Button>
      <Button
        type={numberOfHours === 1344 ? 'primary' : 'default'}
        shape="round"
        size="small"
        onClick={() => setNumberOfHours(1344)}
      >
        8w
      </Button>
      <Button
        style={{
          backgroundColor: offset ? 'green' : 'inherit',
          borderColor: offset ? 'green' : 'inherit'
        }}
        disabled={!offset}
        type="danger"
        shape="circle"
        size="small"
        icon={<CaretRightOutlined />}
        onClick={() => setOffset((prev) => prev - numberOfHours)}
      />

      <Button
        style={{
          backgroundColor: offset ? 'green' : 'inherit',
          borderColor: offset ? 'green' : 'inherit'
        }}
        disabled={!offset}
        type="danger"
        shape="circle"
        size="small"
        icon={<HomeOutlined />}
        onClick={() => setOffset(0)}
      />

      <Button
        // style={{
        //   backgroundColor: largeGraph ? 'green' : 'inherit',
        //   borderColor: largeGraph ? 'green' : 'inherit'
        // }}
        type="primary"
        shape="circle"
        size="small"
        icon={largeGraph ? <ZoomOutOutlined /> : <ZoomInOutlined />}
        onClick={() => setLargeGraph((prev) => (prev ? 0 : 1))}
      />
    </div>
  )
}

export default memo(GraphButtonsHours)
