const userInputsReducer = (
  state = {
    cryptoid: 'Select...',
    direction: 'Select...',
    pricepoint: 0,
    alertType: 'Select...'
  },
  action
) => {
  switch (action.type) {
    case 'STOREUSERINPUTS':
      return Object.assign({}, state, {
        [action.payload.name]: action.payload.value
      })
    // case 'STORE':
    //   let userInputs = {} //{ ...state.userInputs }
    //   userInputs[action.payload.name] = action.payload.value
    //   return userInputs
    // {
    //   // ...state,
    //   userInputs
    // }

    //   case FETCH_NEW_POSTS
    // let posts = [...state.posts]; // we're destructuring `state.posts` inside of array, essentially assigning the elements to a new array.
    // posts.push(action.payload.posts);
    // return {
    //     ...state,
    //     posts
    // };

    // case 'DECREMENT':
    //     return state - action.payload
    default:
      return state
  }
}

export default userInputsReducer
