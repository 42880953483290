import React, { useEffect, useState, memo } from 'react'
import Header from '../HeaderComponent/HeaderComponent'
import { DashboardWrapper } from './styles'
import RenderTable from './Table'
import { Formik, Field, Form } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { storeUser, storeUserInputs, storeAppvariables } from '../../actions'
import { Menu, Dropdown, message, Select } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Input, Tooltip } from 'antd'
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons'
const { Option } = Select

const CreateTrendAlert = ({ user, setuser }) => {
  const dispatch = useDispatch()
  const userInputs = useSelector((state) => state.userInputs)
  const DEBUG = useSelector((state) => state.appvariables.debug)
  const cryptos = useSelector((state) => state.appvariables.cryptos)

  // useEffect(() => {
  //   let cryptoInit = Object.values(cryptos).filter((item) => item.type === 'USD')[0].id
  //   console.log('Setting values...')
  //   dispatch(storeUserInputs('cryptoid', 'Select...'))
  //   dispatch(storeUserInputs('alertType', 'Select...'))
  //   dispatch(storeUserInputs('direction: ', 'Select...'))
  //   console.log('Done.')
  // }, [])

  return (
    <div>
      <Formik initialValues={{}} onSubmit={async (values) => {}}>
        <Form>
          <br />
          <label htmlFor="cryptoid">Crypto: </label>
          <Select
            defaultValue={userInputs.cryptoid}
            style={{ width: '100%' }}
            onChange={(value) => dispatch(storeUserInputs('cryptoid', value))}
          >
            {Object.values(cryptos)
              .filter((item) => item.type === 'USD')
              .map((item) => (
                <Option value={item.id}>
                  {item.id} (Now: {item.currentValue} {item.type.toUpperCase()}, Trend now
                  (15min/30min/2h):{' '}
                  <span style={{ color: item.trend15min < 0 ? 'red' : 'green' }}>
                    {item.trend15min}%
                  </span>
                  /
                  <span style={{ color: item.trend30min < 0 ? 'red' : 'green' }}>
                    {item.trend30min}%
                  </span>
                  /
                  <span style={{ color: item.trend2hour < 0 ? 'red' : 'green' }}>
                    {item.trend2hour}%
                  </span>
                  )
                </Option>
              ))}
          </Select>

          <br />
          <label htmlFor="pricepoint">Percentage change before alert trigger:</label>
          <Input
            type="number"
            id="trendpercentage"
            name="trendpercentage"
            placeholder={0}
            value={userInputs.trendpercentage}
            onChange={(e) => dispatch(storeUserInputs(e.target.name, e.target.value))}
            suffix={userInputs.cryptoid}
            prefix="%"
          />
          <br />
          <label htmlFor="alertTypeMenu">Timeframe: </label>

          <Select
            defaultValue={userInputs.alertType}
            style={{ width: 120 }}
            onChange={(value) => dispatch(storeUserInputs('alertType', value))}
            // onChange={(value) => console.log('TREND NOW: ', value)}
          >
            <Option value="15minTrend">15 min</Option>
            <Option value="30minTrend">30 min</Option>
            <Option value="2hourTrend">2 hour</Option>
          </Select>

          <br />
          <label htmlFor="direction">Direction: </label>
          <Select
            defaultValue={userInputs.direction}
            style={{ width: 120 }}
            onChange={(value) => dispatch(storeUserInputs('direction', value))}
          >
            <Option value="up">Up</Option>
            <Option value="down">Down</Option>
            {/* <Option value="both">Both</Option> */}
          </Select>
        </Form>
      </Formik>
    </div>
  )
}

export default memo(CreateTrendAlert)
