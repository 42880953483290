class AlexWebSocket {
  constructor(url) {
    this.url = url
    this.errorCount = 0
    this.ready = false
    this.ws = null
    this.handlers = { message: [], close: [] }
    this.openConnection()
  }

  on(type, handler) {
    if (this.handlers[type]) this.handlers[type].push(handler)
  }

  emit(type, data) {
    this.handlers[type].forEach((handler) => {
      handler(data)
    })
  }

  openConnection() {
    this.ws = new WebSocket('wss://linux.midteide.com/websocket')

    this.ws.onopen = () => {
      this.ready = true
      console.log('is opened!!')
      // console.log('DEBUG: ', this)
      // this.ws.send('hello??')
    }
    this.ws.onmessage = this.onMessage
    this.ws.onerror = this.onError
    this.ws.onclose = this.onClose
  }

  onMessage = (event) => {
    let data

    try {
      data = JSON.parse(event.data)
    } catch (e) {
      console.warn('Could not parse WS event', event.data)
    }

    if (data) this.emit('message', data)
  }

  onError = (error) => {
    try {
      this.ws.close()
    } catch (e) {
      console.error(error)
    }

    console.error(error)

    if (this.errorCount++ < 5) {
      this.openConnection()
    } else {
      console.error(`Tried connecting to ${this.url} ${this.errorCount} times, giving up`)

      // process.exit(1)
    }
  }

  onClose = (arg) => {
    if (this.ws.readyState === WebSocket.CLOSED) {
      // Do your stuff...
      console.log('OnClose: ', arg)
      this.emit('close', arg)
      this.openConnection()
    } else console.log('OnClose called BUT CONNECTION IS OPEN')
  }
}

export default AlexWebSocket
