import React, { memo, useEffect, useState, useRef } from 'react'
import { getMonthName, getWeekday } from './GraphUtils'
import { ProgressBar } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { formatNumber, getDateAsString } from './GraphUtils'

import { getSummerTimeDifference } from './GraphUtils'

const timeSince = (entry) => {
  let retString = ''
  let d = new Date()
  d = d.getTime() + getSummerTimeDifference() * 60 * 60 * 1000
  let days = 0
  let hoursSinceEntry = parseFloat((d - entry) / (60 * 60 * 1000))
  for (days; hoursSinceEntry > 24; hoursSinceEntry -= 24) {
    days++
    retString = `${days}d, `
  }

  if ((hoursSinceEntry > 1 || days) && hoursSinceEntry < 24) {
    retString += `${Math.floor(hoursSinceEntry)}h `
    hoursSinceEntry -= Math.floor(hoursSinceEntry)
  }

  if (hoursSinceEntry < 1) retString += `${(hoursSinceEntry * 60).toFixed()}min ago`
  return retString
}

const GraphStats = ({
  logEntries = [],
  warmestPoint,
  coldestPoint,
  lastEntryDate,
  avg,
  prevAvg,
  numberOfHours,
  offset,
  onClick
}) => {
  // console.log(logEntries.length, { lastEntryDate })

  if (logEntries.length && lastEntryDate) {
    let arr = logEntries[logEntries.length - 1]?.length ? [...logEntries[0]] : []
    let currency = arr[arr.length - 1].type.toUpperCase()
    let currentValue = parseFloat(arr[arr.length - 1].value)
    let prevValue = parseFloat(arr[arr.length - 2].value)
    let currentTrendAmount = prevValue - currentValue
    let currentTrendPercent = currentTrendAmount / currentValue
    let lowestPoint = coldestPoint[1],
      highestPoint = warmestPoint[1]
    let spread = highestPoint - lowestPoint
    let avstandNed = (currentValue - lowestPoint).toFixed(2)
    let avstandOpp = (highestPoint - currentValue).toFixed(2)
    let avstandProsent = ((100.0 * spread) / lowestPoint).toFixed(2)
    let avgTrend = avg - prevAvg
    let avgTrendprosent = (avgTrend / avg) * 100.0
    if (avg === null) console.log('!!!!!! AVG IS NOT A NUMBER: ', avg)
    let timePeriodText = ''

    if (numberOfHours > 24) {
      timePeriodText = `${numberOfHours / 24}d`
    } else {
      timePeriodText = `${numberOfHours}h`
    }

    let minText = (
      <span>
        {' '}
        {/* <b style={{ color: coldestPoint[1] > 0 ? 'red' : 'blue' }}> */}
        <b style={{ color: 'red' }}>{`${formatNumber(lowestPoint)} ${currency}`} </b>(
        {getDateAsString(coldestPoint[0])})
      </span>
    )

    let maxText = (
      <span>
        <b style={{ color: 'green' }}>
          {formatNumber(highestPoint)} {currency}{' '}
        </b>
        ({getDateAsString(warmestPoint[0])})
      </span>
    )

    let avgText =
      avg === null ? (
        <h1>NULL</h1>
      ) : (
        <span>
          <b style={{ color: 'blue' }}>
            {avg ? formatNumber(avg) : 'N/A'} {currency}{' '}
          </b>
        </span>
      )

    let avstandText = (
      <span>
        <b>
          <span style={{ color: 'green' }}>
            {formatNumber(avstandNed)} {currency}{' '}
          </span>
          /
          <span style={{ color: 'red' }}>
            {' '}
            {formatNumber(avstandOpp)} {currency}{' '}
          </span>
        </b>
      </span>
    )

    let spreadText = (
      <span>
        <b style={{ color: avstandProsent < 3 ? 'green' : avstandProsent < 8 ? 'yellow' : 'red' }}>
          {formatNumber(spread)} {currency}{' '}
        </b>
      </span>
    )

    let avgTrendText = (
      <span>
        <b style={{ color: avgTrend > 0 ? 'green' : 'red' }}>
          {avgTrend > 0 ? '+' : ''}
          {prevAvg ? formatNumber(avgTrend) : 'N/A'} {currency}
        </b>
      </span>
    )
    let avgTrendProsentText = (
      <span>
        <b style={{ color: avgTrend > 0 ? 'green' : 'red' }}>
          {avgTrendprosent > 0 ? '+' : ''}
          {avgTrendprosent.toFixed(2)}%
        </b>
      </span>
    )

    return (
      <div style={{ textAlign: 'center' }} onClick={onClick}>
        {/* <p>{textCurrent}</p> */}
        <p style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
          Stats for {!offset && 'last '} {timePeriodText}
          <span style={{ color: offset && 'red' }}>
            {offset ? `, offset ${offset} hours back in time:` : ':'}
          </span>{' '}
        </p>
        <p>
          Low ({timeSince(coldestPoint[0])}): {minText}{' '}
        </p>
        <p>
          High ({timeSince(warmestPoint[0])}): {maxText}{' '}
        </p>
        <p>
          Average: {avgText} ({avgTrendText}/{avgTrendProsentText}){' '}
        </p>
        <p>
          <span>
            Diff. high/low: {spreadText} ({avstandProsent}%)
          </span>
          <span>, distance to low/high: {avstandText}</span>
        </p>
        <ProgressBar
          min={0}
          max={100}
          now={(100.0 * parseFloat(avstandNed)) / (parseFloat(avstandNed) + parseFloat(avstandOpp))}
        />
      </div>
    )
  }
  return <div>Loading...</div>
}

export default memo(GraphStats)
