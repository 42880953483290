import React, { memo } from 'react'
import { Formik } from 'formik'
import CryptoStatus from '../CryptoStatus/CryptoStatus'
import Dashboard from '../Dashboard/Dashboard'
import { Layout, Menu, Breadcrumb } from 'antd'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import HeaderComponent from '../HeaderComponent/HeaderComponent'

const { Header, Content, Footer } = Layout
const Main = () => {
  return (
    <div>
      <Layout>
        <HeaderComponent />
        <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Main page</Breadcrumb.Item>
          </Breadcrumb>
          <CryptoStatus />
        </Content>
        <Footer style={{ textAlign: 'center' }}>©2021 Created by Alexander Midteide</Footer>
      </Layout>
    </div>
  )
}

export default memo(Main)
